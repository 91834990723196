import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, message, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { fetchData, deleteData } from '../../services/HomeService';
import CallLog from './CallLog';

const CallLogList = ({ isVisible, selectedVendorId, selectedRelationId, handleBaseModalClose }) => {
    const [callLogs, setCallLogs] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCallLog, setSelectedCallLog] = useState(null);

    useEffect(() => {
        if (isVisible) {
            fetchCallLogs();
        }
    }, [isVisible]);

    const fetchCallLogs = async () => {
        try {
            const response = await fetchData(`/call-logs?partnerId=${selectedVendorId}&relationId=${selectedRelationId}`);
            setCallLogs(response.data);
        } catch (error) {
            console.error('Error fetching call logs:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteData(`/call-logs/${id}`, {}, "DELETE");
            message.success('Call log deleted successfully');
            fetchCallLogs();
        } catch (error) {
            console.error('Error deleting call log:', error);
            message.error('Error deleting call log');
        }
    };

    const handleEdit = (record) => {
        setSelectedCallLog(record);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedCallLog(null);
        fetchCallLogs();
    };

    const menu = (record) => (
        <Menu>
            <Menu.Item key="edit" onClick={() => handleEdit(record)}>
                Edit
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => handleDelete(record._id)}>
                Delete
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
        },
        {
            title: 'Duration (seconds)',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Log Date',
            dataIndex: 'logDate',
            key: 'logDate',
            render: (text) => new Date(text).toLocaleString(),
        },
        {
            title: '',
            key: 'actions',
            render: (text, record) => (
                <Dropdown overlay={menu(record)} trigger={['click']}>
                    <Button type="link" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];
    return (
        <Modal 
            open={isVisible}
            onCancel={handleBaseModalClose}
            footer={null}
            title="Call Logs"
        >
           
            <Table columns={columns} dataSource={callLogs} rowKey="_id" pagination={false} />
            <CallLog
                isVisible={isModalVisible}
                onClose={handleModalClose}
                selectedVendorId={selectedVendorId}
                selectedRelationId={selectedRelationId}
                callLog={selectedCallLog}
            />
             <Button type="primary" style={{background: "#4800FF", color: "#fff"}} onClick={() => setIsModalVisible(true)}>+ New Call Log</Button>
        </Modal>
    );
};

export default CallLogList;