import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Select, Input } from 'antd';
import { postData, putData } from '../../services/HomeService';
import dayjs from 'dayjs';

const { Option } = Select;

const CallLog = ({ isVisible, onClose, selectedVendorId, selectedRelationId, callLog }) => {
    const initialCallLogState = {
        partnerId: selectedVendorId || '',
        relationId: selectedRelationId || '',
        logDate: dayjs().toISOString(), // Set to current date and time
        duration: 0,
        logtype: 'outbound', // Always set to 'outbound'
        notes: '',
        status: 'open'
    };

    const [callLogState, setCallLogState] = useState(initialCallLogState);

    useEffect(() => {
        if (callLog) {
            setCallLogState({
                ...callLog,
                logDate: dayjs().toISOString(), // Ensure logDate is always current date and time
                logtype: 'outbound' // Ensure logtype is always 'outbound'
            });
        } else {
            setCallLogState(initialCallLogState);
        }
    }, [callLog, selectedRelationId, isVisible]);

    const handleInputChange = (field, value) => {
        setCallLogState({ ...callLogState, [field]: value });
    };

    const handleSaveCallLog = async () => {
        try {
            if (callLog && callLog._id) {
                
                await putData(`/call-logs/${callLog._id}`, callLogState);
            } else {

                await postData('/call-logs', callLogState);
            }
            onClose();
        } catch (error) {
            console.error('Error saving call log:', error);
        }
    };

    return (
        <Modal
            title="Save Call Log"
            open={isVisible}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" style={{ background: "#4800FF", color: "#fff" }} onClick={handleSaveCallLog}>
                    Save
                </Button>
            ]}
        >
            <Form layout="vertical">
                <Form.Item label="Notes">
                    <Input.TextArea
                        value={callLogState.notes}
                        onChange={e => handleInputChange('notes', e.target.value)}
                    />
                </Form.Item>
                <Form.Item label="Status" style={{ width: '48%', display: 'inline-block', marginRight: '4%' }}>
                    <Select
                        value={callLogState.status}
                        onChange={value => handleInputChange('status', value)}
                    >
                        <Option value="open">Open</Option>
                        <Option value="closed">Closed</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Duration (seconds)" style={{ width: '48%', display: 'inline-block' }}>
                    <Input
                        type="number"
                        value={callLogState.duration}
                        onChange={e => handleInputChange('duration', e.target.value)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CallLog;