import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './Navbar.css';
import PopupPage from "./PopupPage";
import { useMediaQuery } from 'react-responsive';
import MobileLoginAlert from "./MobileLoginAlert";
import { BASE_IMG, MAX_WIDTH_SMALL_LOGIN, MAX_WIDTH_SMALL_SCREEN } from "../../constants";
import { HOMEPAGE_REDIRECT_ROUTE } from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import { clearUser } from "../../helpers/userSlice";
import { fetchData } from "../../services/HomeService";
import { showSuccessMessage, showErrorMessage } from "../../utils";
import rpwhiz from '../../assets/rpwhiz.png';

function Navbar({ showSettings, items, getIcon, profile , company}) {
  const [isProfileSettingsPopup, setIsProfileSettingsPopup] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: MAX_WIDTH_SMALL_SCREEN });
  const isSmalllogin = useMediaQuery({ maxWidth: MAX_WIDTH_SMALL_LOGIN });

  const user = useSelector((state) => state.user); 
  const dispatch = useDispatch();
  const companyConfig =  company && company[1]?.style? company[1]?.style : {};


  useEffect(() => {
    setIsNavbarOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const handleClick = (scrollFlag) => {
    if (isSmallScreen) {
      setIsNavbarOpen(false);
    }

    const url = `${HOMEPAGE_REDIRECT_ROUTE}?scroll=${scrollFlag}`;
    window.location.href = url;
  };

  const handleAvatarClick = () => {
    setIsProfileSettingsPopup(!isProfileSettingsPopup);
  };

  const handleOpenNavbarVertical = () => {
    if (isSmallScreen) {
      setIsNavbarOpen(!isNavbarOpen);
    }
  };

  const handleCloseNavbarVertical = () => {
    if (isSmallScreen) {
      setIsNavbarOpen(false);
    }
  };

  const handleLoginClick = () => {
    if (isSmalllogin) {
      setShowLoginPopup(true);
    }
  };

  const handlePopupClose = () => {
    setShowLoginPopup(false);
  };

  const handlePopupClick = async (item) => {
    setIsProfileSettingsPopup(!isProfileSettingsPopup);
    if(item.linkURL === '/logout'){
      const resp = await fetchData('/users/auth/logout')
      if(resp.success) {
        showSuccessMessage('Successfully logged out!!');
      }else{
        showErrorMessage('Error in logging out-- Session not cleared!!');
      }
      dispatch(clearUser());
      window.location.href = HOMEPAGE_REDIRECT_ROUTE;
    }
  };

  return (
    <div className='Navbar'>
      <div className="nav_divider">
        <div className="responsive_logo-area">
          <img src={`${BASE_IMG}/Responsive_design_images/menu.svg`} alt='menu' className='open-link-container-button' onClick={handleOpenNavbarVertical} />
          <div className='logo-module'>
            {companyConfig.logoUrl ? (
              <>
                <div className='logo'><a href={HOMEPAGE_REDIRECT_ROUTE}><img style={{marginTop: "5px", width: "61px", height: "61px"}} src={companyConfig.logoUrl} alt="RP Whiz" /></a></div>
                {/* <div className='title'><a href={HOMEPAGE_REDIRECT_ROUTE}>RPWHIZ</a></div> */}
              </>
            ) : (
              <>
                <div className='logo'><a href={HOMEPAGE_REDIRECT_ROUTE}><img src={`${BASE_IMG}/raze_logo.svg`} alt="Logo" /></a></div>
                <div className='title'><a href={HOMEPAGE_REDIRECT_ROUTE}><img src={`${BASE_IMG}/RAZE.svg`} alt="Title" /></a></div>
              </>
            )}
          </div>
          <a href={`${HOMEPAGE_REDIRECT_ROUTE}#contact`} onClick={() => handleClick(true)}>
            <img src={`${BASE_IMG}/Responsive_design_images/support.svg`} alt='contact Us' className="nav_headphone_icon" />
          </a>
        </div>
      </div>

      {isNavbarOpen && (
        <>
          <ul className='Links-container' onClick={handleCloseNavbarVertical}>
            {!companyConfig.miniNav ? <>            <li><Link to={`${HOMEPAGE_REDIRECT_ROUTE}/products`} className='Link hover1'>Products</Link></li>
            <li><a href={`${HOMEPAGE_REDIRECT_ROUTE}/pricing`} className='Link hover1'>Pricing</a></li>
            <li><Link to={`${HOMEPAGE_REDIRECT_ROUTE}/team`} className='Link hover1'>Team</Link></li>
            <li><a href={`${HOMEPAGE_REDIRECT_ROUTE}/blogs`} className='Link hover1'>Blogs</a></li>
            </>
            : <></>}
            {showSettings ? (
              <>
                {profile && profile.map((color) =>
                  <div className="profile Link" onClick={handleAvatarClick} key={color.key} style={color.style}>{user?.picture ? <img src={user?.picture} alt="" /> : <span>{user?.name ? user?.name.charAt(0).toUpperCase() : 'U'}</span>}</div>
                )}
                {isProfileSettingsPopup && <PopupPage user={user} items={items} getIcon={getIcon}  handleClick={handlePopupClick} />}
              </>
            ) : (
              <>
                <li className="color_contact">
                  <a href={`${HOMEPAGE_REDIRECT_ROUTE}#contact?scroll=scroll-top`} className='Link color_contact' onClick={() => handleClick("scroll-top")}>Schedule a demo</a>
                </li>
                {isSmalllogin ? (
                  <li>
                    <a className='Link hover1' onClick={handleLoginClick}>
                      Login
                    </a>
                  </li>
                ) : (
                  <li><Link to="/login" className='Link hover1'>Login</Link></li>
                )}
              </>
            )}
            {isSmallScreen && (
              <li className="close-link-container-button-container">
                <img src={`${BASE_IMG}/Responsive_design_images/close_button.svg`} alt='cut' className='close-link-container-button' onClick={handleCloseNavbarVertical} />
              </li>
            )}
          </ul>
        </>
      )}

      {showLoginPopup && isSmalllogin && (
        <MobileLoginAlert handlePopupClose={handlePopupClose} />
      )}
    </div>
  )
}

export default Navbar;