import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './style.css';
import BreadcrumbComponent from '../../common/BreadcrumbComponent';
import { Input, Row, Col, Card, Tag, Button, Modal, Select, Pagination, Typography } from 'antd';
import { SettingOutlined, CheckCircleOutlined, ArrowLeftOutlined, StarFilled, EnvironmentOutlined, PlusOutlined, ArrowDownOutlined, DownOutlined, UpOutlined, StarOutlined } from '@ant-design/icons';
import { BASE_IMG, BASE_URL } from '../../../constants';
import ModalQuotesComponent from '../vendor-list/ModalQuotesComponent';
import { fetchData, postData } from '../../../services/HomeService';
import { showSuccessMessage } from '../../../utils';

const { Option } = Select;

export default function VendorExplore({ selectedParent, selectedChild }) {
    const { Search } = Input;
    const [categories, setCategories] = useState([]);
    const [superCategory, setSuperCategory] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(new Set());
    const [selectedCity, setSelectedCity] = useState([]);
    const [selectedRating, setSelectedRating] = useState(null);
    const [profiles, setProfiles] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [showNewQuoteModal, setShowNewQuoteModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalProfiles, setTotalProfiles] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [showExpandFilter, setShowExpandFilter] = useState(false);
    const [recentlyStarred, setRecentlyStarred] = useState(new Set());
    const [tagFilter, setTagFilter] = useState('');
    const user = useSelector(state => state.user);

    const [showProfiles, setShowProfiles] = useState(false);

    useEffect(() => {
        // Fetch categories from API
        const fetchCategories = async () => {
            try {
                const response = await fetchData(`/categories/tags/feed`);
                setCategories(response);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const handleCategoryChange = (value) => {
        // if (value === "all") {
        //     setSelectedCategory(new Set(categoryOptions.map(val => val.name)));
        //     return;
        // }

        const newSelectedCategory = new Set(selectedCategory);
        newSelectedCategory.delete(value);

        setSelectedCategory(newSelectedCategory);
    };

    const clearFilter = () => {
        setSelectedCategory(new Set(categoryOptions.map(val => val.name)));
    }

    const toggleExpandFilter = () => {
        setShowExpandFilter(!showExpandFilter);
    }

    const handleCityChange = (value) => {
        setSelectedCity(value);
    };

    const handleRatingChange = (value) => {
        setSelectedRating(value);
    };

    const fetchProfiles = async () => {
        try {
            if(tagFilter){
                const response = await fetch(`${BASE_URL}/profiles?filter=${tagFilter}&limit=${pageSize}&page=${currentPage}`); // Replace with your API endpoint
                if (response.status !== 200) throw new Error(response);
                const data = await response.json();
                setProfiles(data.data);
                setTotalProfiles(data.total);
                setShowProfiles(true);
                return;
            }
            const categoryFilter = selectedCategory.size ? `categories-in-${Array.from(selectedCategory).join(',')}` : '';
            const cityFilter = selectedCity.length ? `&city=${selectedCity.join(',')}` : '';
            const ratingFilter = selectedRating ? `&rating=${selectedRating}` : '';
            const response = await fetch(`${BASE_URL}/profiles?filter=${categoryFilter}${cityFilter}${ratingFilter}&limit=${pageSize}&page=${currentPage}`); // Replace with your API endpoint

            if (response.status !== 200) throw new Error(response);
            const data = await response.json();
            setProfiles(data.data);
            setTotalProfiles(data.total);
            setShowProfiles(true);
        } catch (error) {
            console.error('Error fetching profiles:', error);
        }
    };

    useEffect(() => {
        if (superCategory) fetchProfiles();
    }, [superCategory, selectedCategory, selectedCity, selectedRating, currentPage, pageSize, tagFilter]);

    const handleCategoryClick = (category) => () => {
        setSuperCategory(category);
        setTagFilter(category.filter);
        setCategoryOptions(category.categories);
        setSelectedCategory(new Set(category.categories?.map(val => val.name)));
        setShowProfiles(true);
        setShowFilter(true);
    };

    const handleViewProfile = (profile) => () => {
        setSelectedProfile(profile);
        setIsModalVisible(true);
    };

    const handleAskQuote = (profile) => () => {
        setShowNewQuoteModal(true);
    };

    const handleStarClick = (profile) => async () => {
        const payload = { profileIds: [profile._id] }
        const tmpStarredProfile = new Set(recentlyStarred);

        if (recentlyStarred.has(profile._id)) {
            const res = await postData('/profiles/updatestar/unstar', payload);
            tmpStarredProfile.delete(profile._id);
            showSuccessMessage("Unstarred successfully!");
        }
        else {
            const res = await postData('/profiles/updatestar/unstar', payload);
            tmpStarredProfile.add(profile._id);
            showSuccessMessage("Starred successfully!");
        }

        setRecentlyStarred(tmpStarredProfile)
    };

    useEffect(() => {
        const tmpStarredProfile = new Set();

        for (let val of profiles) {
            if (val.starredBy.includes(user.id)) tmpStarredProfile.add(val._id);
        }

        setRecentlyStarred(tmpStarredProfile);

    }, [profiles])

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedProfile(null);
    };

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const onSearch = async () => {
        try {
            const response = await fetch(`${BASE_URL}/profiles?limit=${pageSize}&page=${currentPage}&search=${searchText}`);

            if (response.status !== 200) throw new Error(response);
            const data = await response.json();
            setProfiles(data.data);
            setTotalProfiles(data.total);
            setShowProfiles(true);
            setShowFilter(false);
        } catch (error) {
            console.error('Error fetching profiles:', error);
        }
    }

    return (
        <section className='Home_container'>
            <section className="Home_firstContainer">
                <BreadcrumbComponent selectedParent={selectedParent} selectedChild={selectedChild} />
                <div className="Home_heading">
                    {
                        showProfiles && (
                            <>
                                <Button
                                    type="link"
                                    style={{ padding: 0, height: "28px" }}
                                    onClick={() => {
                                        setSuperCategory(null);
                                        setShowProfiles(false);
                                    }}
                                >
                                    <ArrowLeftOutlined />
                                </Button>
                                <h1><b>Explore Vendors</b></h1>
                            </>
                        )
                    }
                </div>
                <div style={{ maxWidth: '500px', width: "100%", margin: "auto" }}>
                    <Search
                        placeholder="Search for vendors"
                        enterButton={<><SettingOutlined /> Search </>}
                        size="large"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onSearch={onSearch}
                    />
                </div>
            </section>
            <main className='VendorOnboard_adding-2' style={{ flexDirection: "column" }}>
                {!showProfiles ? (
                    <div className="categories-container" style={{ width: "100%" }}>
                        <Row gutter={[16, 16]}>
                            {categories.map(category => (
                                <Col key={category.id} xs={24} sm={12} md={8}>
                                    <div className="category-item" style={{ backgroundImage: `url(${category.image})`, backgroundSize: 'cover' }} onClick={handleCategoryClick(category)}>
                                        <div className="category-overlay">
                                            <h2>{category.label}</h2>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ) : (
                    <div>
                        {
                            showFilter && (
                                <div className='filters' style={{ display: "flex", background: "#fff", padding: "10px", borderRadius: "4px", flexDirection: "column", alignItems: "flex-start", gap: "10px", margin: "16px 0" }}>
                                    <div className='filter-flex'>
                                        <div>
                                            <span style={{ margin: "10px" }}>Category: </span>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            flexWrap: 'wrap',
                                            flex: '1'
                                        }}>
                                            <Tag
                                                onClick={handleCategoryClick}
                                                color='transparent'
                                                style={{
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    marginBottom: '5px',
                                                    color: '#000'
                                                }}
                                            >
                                                All
                                                <PlusOutlined className='filter-cross' />
                                            </Tag>
                                            {
                                                Array.from(selectedCategory).slice(0, 7).map((val, i) => (
                                                    <Tag
                                                        key={i}
                                                        onClick={() => handleCategoryChange(val)}
                                                        color='transparent'
                                                        style={{
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                            marginBottom: '5px',
                                                            color: '#000'
                                                        }}
                                                    >
                                                        {val}
                                                        <PlusOutlined className='filter-cross' />
                                                    </Tag>
                                                ))
                                            }
                                            {
                                                showExpandFilter && Array.from(selectedCategory).slice(7).map((val, i) => (
                                                    <Tag
                                                        key={i}
                                                        onClick={() => handleCategoryChange(val)}
                                                        color='transparent'
                                                        style={{
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                            marginBottom: '5px',
                                                            color: '#000'
                                                        }}
                                                    >
                                                        {val}
                                                        <PlusOutlined className='filter-cross' />
                                                    </Tag>
                                                ))
                                            }
                                        </div>
                                        <Button className='filter-expand' type='link' onClick={toggleExpandFilter}>
                                            Expand {showExpandFilter ? <UpOutlined /> : <DownOutlined />}
                                        </Button>
                                    </div>
                                    <div>
                                        <span style={{ margin: "10px" }}>Filters:</span>
                                        <span style={{ margin: "10px" }}>City:</span>
                                        <Select
                                            allowClear
                                            mode='multiple'
                                            value={selectedCity}
                                            onChange={handleCityChange}
                                            placeholder="Unlimited"
                                            style={{ width: "200px" }}
                                        >
                                            <Option value="Mumbai">Mumbai</Option>
                                            <Option value="Delhi">Delhi</Option>
                                            <Option value="Bangalore">Bangalore</Option>
                                            <Option value="Hyderabad">Hyderabad</Option>
                                            <Option value="Chennai">Chennai</Option>
                                            <Option value="Kolkata">Kolkata</Option>
                                            <Option value="Pune">Pune</Option>
                                        </Select>
                                        <span style={{ margin: "10px", marginLeft: "26px" }}>Rating:</span>
                                        <Select
                                            value={selectedRating}
                                            onChange={handleRatingChange}
                                            style={{ width: "200px" }}
                                            placeholder="Unlimited"
                                        >
                                            <Option value="1">1</Option>
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                            <Option value="4">4</Option>
                                            <Option value="5">5</Option>
                                        </Select>
                                    </div>
                                </div>
                            )
                        }
                        <div className="profiles-container" style={{ width: "100%" }}>
                            <Row gutter={[16, 16]}>
                                {profiles.map(profile => (
                                    <Col key={profile.id} >
                                        <Card
                                            bordered={true}
                                            className='profile-card'
                                            bodyStyle={{ padding: '16px', }}
                                            style={{ minWidth: "250px"  }}
                                            cover={
                                                profile.avatar?.url ? (
                                                    <img alt={profile.name} src={`${profile.avatar.url}`} style={{ height: '100px', width: '100%', objectFit: 'contain', objectPosition: 'center', background: "#fef4f4" }} />
                                                ) : (
                                                    <div className="default-pattern" style={{ height: "100px", background: "linear-gradient(135deg, #f6d365 0%, #fda085 100%)", borderRadius: "8px 8px 0 0" }}></div>
                                                )
                                            }
                                        >
                                            <div className='star-section' style={{ position: 'absolute', top: '8px', right: '8px', background: "rgba(0,0,0,0.25)", borderRadius: "4px", width: '20px', height: '20px', textAlign: 'center' }} onClick={handleStarClick(profile)}>
                                                {
                                                    recentlyStarred.has(profile._id) ? <StarFilled style={{ color: "#fff" }} /> : <StarOutlined style={{ color: "#fff" }} />
                                                }

                                            </div>
                                            <Typography style={{ marginBottom: "8px" }}>{profile.name}</Typography>
                                            <Typography.Text type='secondary'>Description</Typography.Text>
                                            <div style={{ marginTop: "8px" }}>
                                                <Tag icon={<EnvironmentOutlined />} style={{ padding: "2px 8px 2px 8px", borderRadius: "12px" }}>{profile.primeLocation}</Tag>
                                                <Tag icon={<CheckCircleOutlined />} color="success" style={{ padding: "2px 8px 2px 8px", borderRadius: "12px" }}>
                                                    success
                                                </Tag>
                                            </div>
                                            <div style={{ marginTop: "8px", display: "flex", justifyContent: "space-between" }}>
                                                <Button style={{ borderRadius: "1px", width: "45%" }} onClick={handleViewProfile(profile)}>View Profile</Button>
                                                <Button style={{ borderRadius: "1px", border: "1px solid #6D33FF", color: "#6D33FF", width: "45%" }} onClick={handleAskQuote(profile)}>Ask quote</Button>
                                            </div>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={totalProfiles}
                            onChange={handlePageChange}
                            style={{ marginTop: '20px', textAlign: 'center' }}
                        />
                    </div>
                )}
            </main>
            <Modal
                title={selectedProfile?.name}
                visible={isModalVisible}
                onCancel={handleModalClose}
                width={"75%"}
                footer={[
                    <Button key="close" onClick={handleModalClose}>
                        Close
                    </Button>
                ]}
            >
                <iframe
                    title={selectedProfile?.name}
                    src={`https://marketplace.raze.co.in/profile/view/${selectedProfile?.username}?display=embed`}
                    style={{ width: '100%', height: '500px' }}
                ></iframe>
                {/* Add more profile details here */}
            </Modal>
            {
                //TODO: update partnerId and the set default
                showNewQuoteModal && (
                    <ModalQuotesComponent
                        isVisible={showNewQuoteModal}
                        onClose={() => setShowNewQuoteModal(false)}
                        type={'newQuote'}
                        modalData={{ "sinkPartnerId": "66e27920110ddb37204e8e42" }}
                        setTrigger={{}}
                    />
                )
            }
        </section>
    );
}