import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Select, Radio } from 'antd';
import { fetchData, putData } from '../../services/HomeService';

const { Option } = Select;

const PermissionManagerModal = ({ isVisible, onClose, isAssigningVendor=true, vendorSelections,selectedRelationId, vendorData, overallData }) => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        if (isVisible) {
            if(vendorData){
               const  selectedVendor = vendorData.find(vendor => vendor._id === selectedRelationId);
                setSelectedUser(selectedVendor?.assignedTo?._id);
            }
            fetchUsers();
        }
    }, [isVisible]);

    const fetchUsers = async () => {
        try {
            const response = await fetchData(`/users/getall/org`);
            setUsers(response);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleUserChange = (e) => {
        setSelectedUser(e.target.value);
    };


    const handleSavePermission = async () => {
        try {
            //TODO: fix not a post call - put call
           const relationUpdate = await putData(`/relations/${selectedRelationId}`, {state: "verification", status: "pending", assignedTo: selectedUser});
            onClose();
        } catch (error) {
            console.error('Error saving permission:', error);
        }
    };

    return (
        <Modal
        title='Assign to team member'
        open={isVisible}
        onCancel={onClose}
        footer={<Button key="done" type="primary" onClick={handleSavePermission}>Assign</Button>} 
        className="Category_modal"
    >
        <div className='CategoryModal_content'>
        <Radio.Group onChange={handleUserChange} value={selectedUser}>
          {users?.map((option, index) => (
            <Radio key={index} value={option._id} name={option.name}>
              {option.name}
            </Radio>
          ))}
        </Radio.Group>
        </div>

    </Modal>
    );
};

export default PermissionManagerModal;