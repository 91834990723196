import React from "react";
import "./PopupPage.css";
function PopupPage({ items, getIcon, user, handleClick }) {
    return (
        <>
            <div className="popup_container">
                <div className="popup_elements">
                    <li className="contents">
                        <span className="popup_name">{user ? user.name : 'User'}</span>
                    </li>
                    <li className="contents">
                        <span className="popup_icon1">{getIcon("TeamOutlined")}</span>
                        <span className="popup_items">As {user && user.roles? user.roles.join(", ") : 'User'}</span>
                    </li>
                    {items.map((item) =>
                        <li className="contents" key={item.key} onClick={() => handleClick(item)}>
                            <span className="popup_icon1">{getIcon(item.icon)}</span>
                            <span className="popup_items">{item.label}</span>
                        </li>
                    )}
                </div>
            </div>
        </>
    );
}
export default PopupPage;
