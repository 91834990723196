import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { List, Avatar, Popover, Button, Checkbox, Modal, Dropdown, Radio } from 'antd';
import _, { set } from 'lodash';
import ExportModalComponent from './ExportModal';

import './style.css';
import { createNote, updateNote, deleteNote } from '../../../services/ActivitiesService';
import { fetchData, postData, putData } from '../../../services/HomeService';
import ModalDialogComponent from '../../common/ModalDialogComponent';
import ModalEditDetailsComponent from './ModalEditDetailsComponent';
import ModalNotesComponent from './ModalNotesComponent';
import {
    ONBOARDING_COMPLETED_STATUS,
    LINK_URL_NOTES,
    LINK_TYPE_MODAL,
    LINK_URL_VENDOR_PROFILE,
    LINK_ATTACHMENTS,
    LINK_TYPE_ROUTE_INTERNAL,
    LINK_URL_PAYWALL,
    LINK_URL_QUOTES,
    LINK_EDIT_DETAILS,
    LINK_EMAIL_VERIFICATION,
    BASE_IMG,
    LINK_API_CALL
} from '../../../constants';

import ModalPayWallComponent from './ModalPayWallComponent';
import ModalQuotesComponent from './ModalQuotesComponent';
import ModalCategoryComponent from './ModalCategoryComponent';
import { formatToCustomTime, showErrorMessage, showLoader, showSuccessMessage } from '../../../utils';
import ModalAttachmentComponent from './ModalAttachmentComponent';
import { deleteData } from '../../../services/HomeService';
import RequestNudgeComponent from '../../nudge/RequestNudgeComponent';
import { handleVendorAction } from '../../../services/RemoveVendor';
import ListInfoComponent from '../../common/ListInfoComponent';
import PostAskQuoteModal from './PostAskQuoteModal';
import PermissionManagerModal from '../../common/PermissionManagerModal';
import CallLog from '../../common/CallLog';
import CallLogList from '../../common/CallLogList';

export default function ListViewComponent({ overallData,fetchItems, viewType, vendorData, AvatarColor, categoriesList, setVendorData, setPagination, pagination, selectAll, setSelectAll, vendorSelections, setVendorSelections, setAllSelectedVendorId }) {
    const [showPopup, setShowPopup] = useState(null);
    const [popoverOnboarding, setPopoverOnboarding] = useState(null);
    const [partnerId, setpartnerId] = useState(null);
    const [selectedKey, setSelectedKey] = useState(null);
    const [vendorInfo, setVendorInfo] = useState(null);
    const [relationId, setRelationId] = useState(null);
    const [vendorPartnerId, setVendorPartnerId] = useState(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [selectedVendorId, setSelectedVendorId] = useState(null);

    const [notesData, setNotesData] = useState([]);
    const [activeNoteData, setActiveNoteData] = useState(null);
    const [isDailogBoxVisible, setIsDailogBoxVisible] = useState(false);
    const [isNotesModalVisible, setIsNotesModalVisible] = useState(null);
    const [isPayWallModalVisible, setIsPayWallModalVisible] = useState(false);
    const [isQuotesModalVisible, setIsQuotesModalVisible] = useState(false);
    const [isEditorModalVisible, setIsEditModalVisible] = useState(false);
    const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
    const [isAttachmentsModalVisible, setIsAttachmentsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [showPermissionManagerModal, setShowPermissionManagerModal] = useState(false);
    const [selectedRelationId, setSelectedRelationId] = useState(null);
    const [updatePayment, setUpdatePayment] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(1);
    const [callLogsModal, setCallLogsModal] = useState(false);


    const [isPostQuoteModalVisible, setIsPostQuoteModalVisible] = useState(false);
    const [postQuoteData, setPostQuoteData] = useState({ quoteId: "", partnerIds: [] });

    const user = useSelector((state) => state.user);
    const closeExportModal = () => setShowExportModal(false);


    const navigate = useNavigate();

    const handleTrippleDot = (item, visible) => {
        if (visible) {
            setShowPopup(item);
        } else {
            setShowPopup(null);
        }
    };

    const openPostQuoteModal = (quoteId, partnerIds) => {
        setIsPostQuoteModalVisible(true);
        setPostQuoteData({ quoteId, partnerIds });
    }

    const closePostQuoteModal = () => {
        setIsPostQuoteModalVisible(false);
        setPostQuoteData(null);
    }
    const closePermissionManagerModal = () => {
        setShowPermissionManagerModal(false);
        console.log('closePermissionManagerModal')
        fetchItems();
    }

    const renderModalComponent = (data, linkURL, key) => {
        switch (linkURL) {
            case LINK_URL_NOTES:
                openNotesModal(data)
                break;
            case LINK_URL_PAYWALL:
                openPayWallModal(key);
                break;
            case LINK_URL_QUOTES:
                openQuotesModal(data)
                break;
            case LINK_EDIT_DETAILS:
                openEditModal(data);
                break;
            case LINK_ATTACHMENTS:
                openAttachmentModal(data);
                break;
            case 'permission-manager':
                setShowPermissionManagerModal(true);
                setSelectedRelationId(data._id);
                break;
            case 'update-payment': 
                setUpdatePayment(true);
                setSelectedRelationId(data._id);
                break;
            case 'call-logs': 
                setCallLogsModal(true); 
                setSelectedRelationId(data._id);

            default:
                break;
        }
    }

    const handleOptionClick = (item, option) => {
        setShowPopup(null);
        if (option.linkType === LINK_TYPE_MODAL) {
            console.log('modal')
            renderModalComponent(item, option.linkURL, option.key)
        } else if (option.linkType === LINK_TYPE_ROUTE_INTERNAL) {
            // TODO: Make the query param depended on response- change API
            if (option.linkURL && option.linkURL.includes(LINK_URL_VENDOR_PROFILE)) {
                navigate(`${option.linkURL}?id=${item.sinkPartnerId}`);
            } else if(option.linkURL?.includes('lead-profile')){
                navigate(`${option.linkURL}?id=${item.sinkPartnerId}`);
            }
            else if (option.linkURL === LINK_EMAIL_VERIFICATION) {
                sendEmailVerification(item.sinkPartnerId);
            }
        } else if (option.linkType === LINK_API_CALL) {
            if (option.linkURL === LINK_EMAIL_VERIFICATION) {
                sendEmailVerification(item.sinkPartnerId);
            }
            if (option.linkURL === 'export') {
                console.log('export')
                setSelectedVendorId(item._id)
                setShowExportModal(true);
            }
            
            else if(option.linkURL === 'lock-profile'){
                handleVendorAction(item, 'lock-profile');
            }
            else{
                if(option.linkURL === 'kyc'){
                handleVendorAction(item.sinkPartnerId, 'kyc');
            }else{
                openRemoveVendorModal(item._id)
            }
            }
        }
    }
    const handlePaymentChange = (e) => {
        setPaymentStatus(e.target.value)
    }
    const handlePaymentStatus = async () => {
        try {
            let data = {}
            if(paymentStatus === 1){
                data = { state: "onboarding", status: "completed" }
            }
            else{
                data = { state: "verification", status: "completed" }
            }
            const response = await putData(`/relations/${selectedRelationId}`, data);
            if (response.success) {
                showSuccessMessage(response.message || 'Request successfully submitted!!');
                setUpdatePayment(false);
            } else {
                showErrorMessage(response.message);
            }
        } catch (error) {
            showErrorMessage('Some error happened!!');
        }
    }

    const openRemoveVendorModal = (id) => {
        setSelectedVendorId(id)
        setShowRemoveModal(true);
    }
    const closeRemoveVendorModal = () => {
        setShowRemoveModal(false);
    }

    const openEditModal = (data) => {
        setIsEditModalVisible(true);
        setVendorPartnerId(data.sinkPartnerId);
    }

    const closeEditModal = () => {
        setIsEditModalVisible(false);
    }

    const openAttachmentModal = (data) => {
        setVendorPartnerId(data.sinkPartnerId);
        setIsAttachmentsModalVisible(true);
    };

    const closeAttachmentModal = () => {
        setIsAttachmentsModalVisible(false);
    }

    const sendEmailVerification = async (id) => {
        const requestData = { pid: id }
        setIsLoading(true);
        try {
            const response = await postData("/notifications/onboard-partner", requestData);
            if (response.success) {
                showSuccessMessage("Request successfully submitted!!");
            } else {
                showErrorMessage(response.message);
            }
        } catch (error) {
            showErrorMessage(error.message);
        } finally {
            setIsLoading(false)
        }
    }

    const handleSelectedVendorAction = async (id, action) => {
        try {
            const { success } = await handleVendorAction(id, action);
            if (success) {
                const updatedVendorData = vendorData.filter(vendor => vendor._id !== id);
                const updatedPagination = {
                    ...pagination,
                    total: pagination.total - 1
                };
                setVendorData(updatedVendorData);
                setPagination(updatedPagination);
            }
        } catch (error) {
            showErrorMessage(error.message);
        } finally {
            setShowRemoveModal(false);
        }
    };

    const openQuotesModal = (data) => {
        setIsQuotesModalVisible(true);
        setVendorInfo(data);
    }

    const CloseQuotesModal = () => {
        setIsQuotesModalVisible(false);
    };

    const openPayWallModal = (key) => {
        setIsPayWallModalVisible(true);
        setSelectedKey(key);
    };

    const ClosePayWallModal = () => {
        setIsPayWallModalVisible(false);
    };

    const fetchNotes = async (id = partnerId) => {
        const data = await fetchData(`/activities/action/note?pid=${id}`);
        setNotesData(data);
    };

    const openNotesModal = async (item) => {
        setIsNotesModalVisible(true);
        setpartnerId(item.sinkPartnerId);
        fetchNotes(item.sinkPartnerId);
    };

    const handlePopupClose = () => {
        setIsDailogBoxVisible(false);
        setActiveNoteData(null);
    };

    const setNoteAction = (id, value, actionType) => {
        if (actionType === 'edit') {
            if (!value) window.alert('You cannot set empty fields')
        } else if (actionType === 'delete') {
            if (!id) window.alert('You cannot delete this fields')
        } else if (actionType === 'add') {
            if (id) window.alert('You cannot add to this fields')
        }
        //TODO: Remove note identifier from key val pairs
        setActiveNoteData({
            noteId: id,
            noteValue: value,
            noteAction: actionType,
        });
    };

    const handleModal = async (data = {}) => {
        try {
            if (data && data.noteAction === 'delete' && data.noteId) {
                await deleteNote(data.noteId)
            } else {
                if (data && data.noteAction === 'edit' && data.noteId) {
                    await updateNote({ id: data.noteId, actionValue: data.noteValue });
                } else if (data && data.noteAction === 'add' && data.noteValue !== '') {
                    const requestBody = {
                        type: 'note',
                        pid: partnerId,
                        value: data.noteValue,
                    };
                    await createNote(requestBody);
                }
            }
            handlePopupClose();
            fetchNotes();
        } catch (error) {
            console.error('API error:', error);
        }
    };

    const handleCategoryAssignment = async (category) => {
        try {
            if (!relationId || !category) throw new Error("Some mandatory fields are empty");
            const response = await putData(`/relations/${relationId}`, { category });
            if (response.success) {
                setVendorData((prevVendorData) =>
                    prevVendorData.map((_vendor) =>
                        _vendor._id === relationId ? { ..._vendor, category: _.find(categoriesList, { value: category }).label }
                            : _vendor
                    )
                );
                showSuccessMessage(response.message || 'Request successfully submitted!!');
                fetchItems();
            } else {
                showErrorMessage(response.message);
            }
        } catch (error) {
            console.log(error.message)
            showErrorMessage('Some error happened!!');
        }
    };

    const handleAssignCategoryButtonClick = (rid) => {
        setRelationId(rid)
        setIsCategoryModalVisible(true);
    };

    const handleAssigneToButtonClick = (rid) => {
        setSelectedRelationId(rid)
        setShowPermissionManagerModal(true);
    };

    const CloseCategoryModal = () => {
        setIsCategoryModalVisible(false);
        // fetchItems();
    };

    const handleSelectToggle = (_id) => {
        const updatedVendorSelections = {
            ...vendorSelections,
            [_id]: !vendorSelections[_id],
        };
        const allSelected = vendorData.every((vendor) => updatedVendorSelections[vendor._id]);
        const selectedVendorIds = Object.keys(updatedVendorSelections).filter(_id => updatedVendorSelections[_id]);

        if (!updatedVendorSelections[_id]) {
            delete updatedVendorSelections[_id];
        }
        setVendorSelections(updatedVendorSelections);
        setAllSelectedVendorId(selectedVendorIds);
        setSelectAll(allSelected);
    };

    return (
        <section className="ListComponent_container" style={{ width: '100%' }}>
            <List
                className="demo-loadmore-list"
                itemLayout="horizontal"
                dataSource={vendorData}
                renderItem={(item) => (
                    <List.Item>
                        <div className="ListComponent_box2">
                            <ListInfoComponent
                                name={item.sinkPartnerName}
                                id={item._id}
                                AvatarColor={AvatarColor}
                                type={'VendorList'}
                                badges={item.options.badges}
                                vendorSelections={vendorSelections}
                                selectAll={selectAll}
                                pagination={pagination}
                                handleSelectToggle={handleSelectToggle}
                                popoverOnboarding={popoverOnboarding}
                                setPopoverOnboarding={setPopoverOnboarding}
                                published={item.locked}
                                fields={item.fields}
                                assignedTo={item.assignedTo}
                    />

                            <div className={pagination.total > 1 ? 'ListComponent_subCategory' : 'ListComponent_subCategory2'}>
                                {item.category ? (
                                    <p>{_.find(categoriesList, { value: item.category })?.label}</p>
                                ) : (
                                    <p onClick={() => handleAssignCategoryButtonClick(item._id)} style={{ color: '#4800FF', cursor: 'pointer' }}>Set</p>
                                )}
                            </div>
                            { user?.domain == 'rpwhiz.com' ? <div className='listinfo-applicationid'>
                                    {item.assignedTo?.name ? (
                                        <p>{item.assignedTo.name}</p>
                                    ) : (
                                        <p onClick={() => handleAssigneToButtonClick(item._id)} style={{ color: '#4800FF', cursor: 'pointer' }}>Assign</p>
                                    )}
                                </div>: <div className='listinfo-applicationid' style={{ marginRight: "20px" }}>
                                    <p>{item.universal_id}</p>
                                </div> 
                            }
                            <div className="ListComponent_box1" style={{ width: '100%', justifyContent: 'flex-end' }}>
                                {item.updatedAt ? (
                                    <p>Last Updated on {formatToCustomTime(item.updatedAt)}</p>
                                ) : item.createdAt ? (
                                    <p>Created on {formatToCustomTime(item.createdAt)}</p>
                                ) : ''}
                                {item.options && item.options.horizontalbar && (
                                    <>
                                        {item.options.horizontalbar?.map((option) => (
                                            <span key={option.key} style={{ cursor: 'pointer', color: '#4800FF' }} onClick={() => handleOptionClick(item, option)}>
                                                {option.label}
                                            </span>
                                        ))}
                                    </>
                                )}
                                { _.get(AvatarColor?.[1]?.style, 'showStatus', true) && ONBOARDING_COMPLETED_STATUS !== `${item.state}-${item.status}`
                                    && <p>
                                        {item.state} {item.status}
                                    </p>}
                                <Popover
                                    content={
                                        <div className="list_popup">
                                            {item.options && item.options.tripledots?.map((option) => (
                                                <span key={option.key} onClick={() => handleOptionClick(item, option)}>
                                                    {option.label}
                                                </span>
                                            ))}
                                        </div>
                                    }
                                    overlayClassName="list_popover"
                                    trigger="click"
                                    placement="bottomLeft"
                                    open={showPopup === item}
                                    onOpenChange={(visible) => handleTrippleDot(item, visible)}>
                                    <MoreOutlined className="ListComponent_moreIcon" />
                                </Popover>
                            </div>
                        </div>
                    </List.Item>
                )}
            />
            {isLoading && showLoader('sending')}
            <ModalNotesComponent
                modalData={notesData}
                setIsModalVisible={setIsNotesModalVisible}
                setDailogBoxVisible={setIsDailogBoxVisible}
                isModalVisible={isNotesModalVisible}
                handleModalAction={setNoteAction}
            />

            <ModalDialogComponent
                type={'note'}
                modalData={activeNoteData}
                isVisible={isDailogBoxVisible}
                handleModalAction={handleModal}
                onCancel={handlePopupClose}
            />

            <ModalPayWallComponent
                isVisible={isPayWallModalVisible}
                onClose={ClosePayWallModal}
                selectedKey={selectedKey}
            />
            <ModalQuotesComponent
                openPostQuoteModal={openPostQuoteModal}
                isVisible={isQuotesModalVisible}
                onClose={CloseQuotesModal}
                modalData={vendorInfo}
            />
            <PostAskQuoteModal
                isVisible={isPostQuoteModalVisible}
                postQuoteData={postQuoteData}
                onClose={closePostQuoteModal}
            />
            {isEditorModalVisible && (
                <ModalEditDetailsComponent
                    registerType={viewType}
                    isVisible={isEditorModalVisible}
                    onClose={closeEditModal}
                    modalData={vendorPartnerId}
                />
            )}
            <ModalCategoryComponent
                modalData={categoriesList}
                isVisible={isCategoryModalVisible}
                onClose={CloseCategoryModal}
                handleModalAction={handleCategoryAssignment}
            />
            {isAttachmentsModalVisible && (
                <ModalAttachmentComponent
                    isVisible={isAttachmentsModalVisible}
                    onClose={closeAttachmentModal}
                    modalData={vendorPartnerId}
                />
            )}
            <RequestNudgeComponent
                type={'removeVendor'}
                action={'remove'}
                data={selectedVendorId}
                isVisible={showRemoveModal}
                onClose={closeRemoveVendorModal}
                handleNudgeSubmit={handleSelectedVendorAction}
            />
            <ExportModalComponent
                isVisible={showExportModal}
                onClose={closeExportModal}
                vendorSelections={vendorSelections}
                singleId={selectedVendorId}
                vendorData={vendorData}
                overallData={overallData}
                user={user}
            />
            <PermissionManagerModal
                 isVisible={showPermissionManagerModal}
                 onClose={closePermissionManagerModal}
                 vendorSelections={vendorSelections}
                 selectedRelationId={selectedRelationId}
                 singleId={selectedVendorId}
                 vendorData={vendorData}
                 overallData={overallData}
                 user={user}
            />
            <Modal
                title="Update Payment"
                visible={updatePayment}
                onCancel={() => setUpdatePayment(false)}
                footer={[
                    <Button key="cancel" onClick={() => setUpdatePayment(false)}>
                        Cancel
                    </Button>,
                    <Button key="save" type="primary" onClick={() => handlePaymentStatus()}>
                        Save
                    </Button>
                ]}
            >
                <p>Update Payment</p>
               <Radio.Group onChange={(e)=>handlePaymentChange(e)}>
                     <Radio value={1}>Paid</Radio>
                     <Radio value={2}>Hold</Radio>
                </Radio.Group>

            </Modal>
            <CallLogList 
                isVisible={callLogsModal} 
                selectedVendorId={selectedVendorId} 
                selectedRelationId={selectedRelationId} 
                handleBaseModalClose={() => setCallLogsModal(false)}
            />


        </section>
    );
}