import { Button, Modal, Radio } from 'antd'
import React, { useState } from 'react'

export default function ModalCategoryComponent({modalData:options, isVisible, onClose, handleModalAction}) {

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleAssignClick = () => {
    handleModalAction(selectedOption);
    onClose();
  };

  return (
    <Modal
        title='Select Category'
        open={isVisible}
        onCancel={onClose}
        footer={<Button key="done" type="primary" onClick={handleAssignClick}>Assign</Button>} 
        className="Category_modal"
    >
        <div className='CategoryModal_content'>
        <Radio.Group onChange={handleOptionChange} value={selectedOption}>
          {options?.map((option, index) => (
            <Radio key={index} value={option.value} name={option.label}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
        </div>

    </Modal>
  )
}
