import React, { useEffect, useState } from "react";
import "./style.css";
import moment from 'moment';
import _ from 'lodash';

import BreadcrumbComponent from "../../common/BreadcrumbComponent";
import { Avatar, Popover } from "antd";
import { BellOutlined, BellFilled } from "@ant-design/icons"; // Import BellFilled icon
import WelcomNudgeComponent from "../../nudge/WelcomeNudgeComponent";
import SubscribeNudgeComponent from "../../nudge/SubscribeNudgeComponent";
import EditCompanyNameModal from "../EditCompanyNameModal";
import NotificationListComponent from "../../common/NotificationListComponent";
import { notifications, taskListItems, insightsData, exploreData} from "../Data";
import TaskListComponent from "../TaskListComponent";
import ProgressBarComponent from "../../common/ProgressBarComponent";
import InsightNudgeComponent from "../../nudge/InsightNudgeComponent";
import ExploreNudgeComponent from "../../nudge/ExploreNudgeComponent";

import { showErrorMessage, showSuccessMessage, showWarningMessage } from "../../../utils";
import { putData, fetchData } from "../../../services/HomeService";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../../helpers/userSlice";
import CoPilotNudgeComponent from "../../nudge/CoPilotNudgeComponent";
import { uploadFile } from "../../../services/UploadService";


export default function DashboardOverall({ selectedParent, selectedChild, AvatarColor }) {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [addVendorProgress, setAddVendorProgress] = useState(false);
  const [vendorData, setVendorData] = useState('');
  const [isNotification, setIsNotification] = useState(false); // TODO : setIsNotification will set when notification api call 
  const userData = useSelector((state) => state.user); 
  const [progressData, setProgressData] = useState({});
  const dispatch = useDispatch();

  const [companyName, setCompanyName] = useState(_.get(userData, 'orgName', '') || (userData?.orgType === 'vendor' ? 'Vendor Name' : 'Company Name'));
  const [companyImg, setCompanyImg] = useState(_.get(userData, 'orgPic', '') || null)

  //TODO: Shift these flags to config API for components visibility
  const componentVisibility = {
    TaskList: true,
    ProgressBar: !((userData && userData?.org) && addVendorProgress),
    WelcomNudge: true,
    ExploreNudge: true,
    InsightNudge: true,
    SubscribeNudge: true,
    CoPilotNudge: false,
  };

  useEffect(() => {
    const fetchItems = async () => {
      if (!userData || !userData?.org) return;
      const responseData = await fetchData(`/relations/source/${userData?.org}/vendor`)
      setVendorData(responseData.total)

      if (_.get(userData, 'orgName', '') && _.get(userData, 'orgPic', '')) {
        setIsEditModalVisible(false);
      }
    };

    fetchItems();
  }, [userData]);

  useEffect(() => {
    const fetchVendorData = async () => {
      const progress = ((vendorData > 0 && vendorData <= AvatarColor[0]?.minVendorsListCount) || vendorData === 0) ? false : true;
      setAddVendorProgress(progress);
      if (userData?.orgType === 'vendor') {
        try {
          const response = await fetchData(`/partners/${userData?.org}`);
          if (response.progress) {
            setAddVendorProgress(false);
            setProgressData(response.progress);
          }
        } catch (error) {
          console.error('Error fetching vendor data:', error);
        }
      }
    };
  
    fetchVendorData();
  }, []);


  const handleEditClick = () => {
    setIsEditModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsEditModalVisible(false);
  };

  const handlePopoverClick = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  const handleSubmit = async (name) => {
    try {
      const id = _.get(userData, 'id', '');
      const email = _.get(userData, 'email', '');
      if(!id|| !email) return
      const requestPayload = { orgName : name, email}
      const response = await putData(`/users/${id}`, requestPayload);
      if (response.success) {
        setCompanyName(name);
        dispatch(updateUser(requestPayload));
        showSuccessMessage('Request successfully submitted!!');
      } else {
        showErrorMessage(response.message);
      }
    } catch (error) {
      console.log(error.message)
      showErrorMessage('Some error happened!!');
    }
  };

  const handleUploadChange = async (file) => {
    try {
      const id = _.get(userData, 'id', '');
      const email = _.get(userData, 'email', '');
      const org = _.get(userData, 'org', '');
      if(!id|| !email) return
      if (!org) {
        showWarningMessage('Please add your account details');
        return;
      }
      const formdata = new FormData();
      formdata.append('file', file);
      const response = await uploadFile(`/uploads/org`, formdata);
      if (response.success) {
        setCompanyImg(response?.data?.download_url);
        dispatch(updateUser({ orgPic: response?.data?.download_url }));
        showSuccessMessage('Updated successfully!!');
      } else {
        showErrorMessage(response.message);
      }
    } catch (error) {
      console.log(error.message)
      showErrorMessage('Some error happened!!');
    }
  }

  return (
    <section className="Home_container">
      <section className="Home_firstContainer">
        <BreadcrumbComponent selectedParent={selectedParent} selectedChild={selectedChild}/>
        <div className="Home_heading">
          <h1>See All Updates</h1>
        </div>
      </section>
      <div className="Dashboard_conatiner">
        <div className="Dashboard_leftSubConatiner">
          <div className="Dashboard_title">
          <Avatar style={_.get(userData, 'orgName', '') ? AvatarColor?.[0]?.style : (_.get(userData, 'orgType') === 'vendor' ? { backgroundColor: '#C2F2E9' } : { backgroundColor: '#D9D9D9' })} src={companyImg}>
            {_.get(userData, 'orgName', '').charAt(0).toUpperCase() || <span className="logo-text">LOGO</span>}
          </Avatar>
            <h4>{companyName}</h4>
          </div>
          {(!_.get(userData, 'orgName', '') || !_.get(userData, 'orgPic', '')) && (
            <p style={userData?.orgType === 'vendor' ? { color: '#008065' } : {}} onClick={handleEditClick}>Edit</p>
          )}
        </div>
        <div className={`Dashboard_rightSubConatiner ${userData?.orgType === 'vendor' && 'colorG'}`}>
          <div className={`Dashboard_notificationContainer ${isPopoverVisible ? "active" : "" }`}>
            <Popover
              content={<NotificationListComponent notifications={notifications} />}
              placement="bottomRight"
              overlayClassName="popover-no-arrow"
              trigger="click"
              open={isPopoverVisible}
              onOpenChange={handlePopoverClick}>
              <div onClick={handlePopoverClick}>
                {isPopoverVisible ? <BellFilled /> : <BellOutlined />}
              </div>
              {isNotification && <div className="Dashboard_notification" />}
            </Popover>
          </div>
          <h5>{moment().format("dddd, DD MMMM, YYYY")}</h5>
        </div>
      </div>
      <div className="Dashboard_container-2">
        {componentVisibility.WelcomNudge && <WelcomNudgeComponent />}
        {componentVisibility.SubscribeNudge && <SubscribeNudgeComponent />}
      </div>
      <div className="Dashboard_conatiner-3">
        <div className="Dashboard_subConatiner-3">
          {componentVisibility.ProgressBar && <ProgressBarComponent vendorData={vendorData} progressData={progressData} vendorListCount={AvatarColor}/>}
          {componentVisibility.TaskList && <TaskListComponent taskListItems={taskListItems} />}
        </div>
        <div className="Dashboard_subConatiner-3-1">
          {componentVisibility.CoPilotNudge && userData?.orgType === 'vendor' && <CoPilotNudgeComponent/>}
          {componentVisibility.ExploreNudge && <ExploreNudgeComponent exploreData={exploreData} />}
          {componentVisibility.InsightNudge && <InsightNudgeComponent insightsData={insightsData} />}   
        </div>
      </div>
      <EditCompanyNameModal isVisible={isEditModalVisible} onClose={handleModalCancel} handleSubmit={handleSubmit} handleUploadChange={handleUploadChange}/>
    </section>
  );
}
