import { RightOutlined, CheckOutlined, EditOutlined, InfoCircleOutlined, MoreOutlined, PlusOutlined, UploadOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Popover, Tooltip, Table, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { BASE_IMG, BASE_URL } from '../../../constants';
import { formatToCustomTime, showErrorMessage, showSuccessMessage } from '../../../utils';
import _, { set } from 'lodash';
import StatusPopoverComponent from '../../common/StatusPopoverComponent';
import { fetchData, postData, putData } from '../../../services/HomeService';
import EditableLabel from '../../common/EditableLabel';

export default function AttachFilesComponent({ theme, attachedData, onAttachNew, openUploadModal, openDeleteModal }) {
    const [isEditing, setIsEditing] = useState(null);
    const [attachmentSections, setAttachmentSections] = useState({});
    const [popoverGrant, setPopoverGrant] = useState(false);
    const [validToken, setValidToken] = useState(null);
    const [open, setOpen] = useState(false);
    const [linkTorender, setLinkTorender] = useState({});
    const [billingModalVisible, setBillingModalVisible] = useState(false);
    const [billingModalId, setBillingModalId] = useState(null);
    const [billingData, setBillingData] = useState({
        amount: '',
        category: '',
        paymentMode: '',
        paymentDate: '',
        paymentReference: '',
        paymentStatus: ''
    });

    const showDrawer = (value) => {
        setLinkTorender(value);
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setLinkTorender({});
    };

    useEffect(() => {
        const _attachedData = _.map(attachedData, (value, key) => { return { ...value, fieldKey: key } });
        setAttachmentSections(_.groupBy(_attachedData, 'fieldSection'));
    }, [attachedData]);

    const fetchItems = async () => {
        const data = await fetchData('/uploads/valid-token');
        setValidToken(data.success);
    };

    useEffect(() => {
        fetchItems();
    }, []);

    const handleEditClick = (key) => {
        setIsEditing(key);
    };

    const handleSaveType = async (data) => {
        const requestData = {
            id: data.fieldMeta?.id,
            label: data.fieldLabel
        };
        const response = await postData("/activities/attachment/custom-field", requestData);
        if (response.success) {
            setIsEditing(null);
            showSuccessMessage('Label updated successfully');
        } else {
            showErrorMessage('Failed to update label');
        }
    };

    const handleFieldLabelChange = (e, fieldKey) => {
        const { value } = e.target;
        setAttachmentSections(prevSections => {
            const updatedSections = _.cloneDeep(prevSections);
            _.forEach(updatedSections, section => {
                const attachment = _.find(section, { fieldKey });
                if (attachment) {
                    attachment.fieldLabel = value;
                    return false;
                }
            });
            return updatedSections;
        });
    };

    const handleModalTrippleDot = () => {
        // TODO : handle popover
    };

    //TODO: Move this attach new button to main component    
    const handleNewAttachment = () => {
        onAttachNew();
    };

    const openPopupForAuthorization = () => {
        //TODO: Fix the popup close with success failure - screen and cross origin
        const popup = window.open(`${BASE_URL}/uploads/approve-drive`, "popup", "popup=true,width=500,height=650");
        const checkPopup = setInterval(() => {
            if (popup.window.location.href.includes(`${window.location.origin}/success`)) { popup.close(); setValidToken(true); }
            if (!popup || !popup.closed) return;
            clearInterval(checkPopup);
        }, 1000 * 20);
    };

    const getRequestType = (fieldType, meta) => {
        switch (fieldType) {
            case 'Verification':
                return 'Request Verification';
            case 'Esign':
                return 'Request Esign';
            case 'Read':
                return 'Extract Information';
            case 'Billing':
                if(meta?.amount) return 'Expense Added';
                return 'Add to Expense';
            default:
                return '';
        }
    };

    const handleActionClick = (id, actionType,data) => {
        if (actionType === 'Billing') {
            if(data?.amount) {
                showSuccessMessage('Expense already added');
                return;
            }
            openBillingModal(id);
        } else {
            showSuccessMessage('Contact admin for this action');
        }
    };

    const openBillingModal = (id) => {
        setBillingModalVisible(true);
        setBillingModalId(id);
    };

    const closeBillingModal = () => {
        setBillingModalVisible(false);
        setBillingModalId(null);
        setBillingData({
            amount: '',
            paymentDate: '',
            paymentReference: '',
            paymentStatus: ''
        });
    };

    const handleBillingInputChange = (field, value) => {
        setBillingData({ ...billingData, [field]: value });
    };

    const handleSaveClick = async () => {
        try {
            const requestData = {
                ...billingData,
                id: billingModalId
            };
            const response = await putData(`/activities/meta/${billingModalId}`, {meta: requestData});
            if (response.success) {
                showSuccessMessage('Billing information updated successfully');
                closeBillingModal();
            } else {
                showErrorMessage('Failed to update billing information');
            }
        } catch (error) {
            console.error('Error updating billing information:', error);
            showErrorMessage('Error updating billing information');
        }
    };

    const columns = [
        {
            title: 'Header',
            dataIndex: 'header',
            key: 'header',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
    ];

    const dataSource = [
        {
            key: '1',
            header: 'Requested By',
            value: linkTorender.fieldMeta?.createdByUserName,
        },
        {
            key: '2',
            header: 'Created TimeStamp',
            value: formatToCustomTime(linkTorender.createdAt),
        },
        {
            key: '3',
            header: 'Expiry TimeStamp',
            value: formatToCustomTime(linkTorender.expiry_date),
        }
    ];

    const buttonStyle = theme ? { backgroundColor: theme, borderColor: theme, color: '#fff' } : {};
    const buttonStyle2 = theme ? { color: theme } : { color: "#4800FF" };

    return (
        <section className='ProfileView_container-2' style={{ position: "relative" }}>
            <div className='ProfileView_middle'>
                <div className='ProfileView_heading'>
                    <h3>Attachments</h3>
                    <p>Upload business related documents and certificates</p>
                </div>
                {!validToken ? (
                    <Button onClick={openPopupForAuthorization} style={{ ...buttonStyle, marginRight: "20px" }}>
                        <img src={`${BASE_IMG}/dashboard-img/dashboard_icons/white_drive.svg`} alt='' /> Grant Access
                    </Button>
                ) : (
                    <div className='ProfileView_grant'>
                        <img src={`${BASE_IMG}/dashboard-img/dashboard_icons/blue_drive.svg`} alt='' />
                        <span>Access Granted</span>
                        <StatusPopoverComponent
                            className={'ProfileView_tickPopover'}
                            image={`${BASE_IMG}/check_circle.svg`}
                            popoverContent={
                                <div className='ListComponent_tick'>
                                    <InfoCircleOutlined />
                                    <div className='ListComponent_tickDetail'>You have successfully granted access to your google drive</div>
                                </div>
                            }
                            popoverVisible={popoverGrant}
                            setPopoverVisible={() => setPopoverGrant(!popoverGrant)}
                        />
                    </div>
                )}
            </div>
            {_.keys(attachmentSections).map((section, index) => (
                <div className='ProfileView_subContainer' key={index}>
                    <p>{section}</p>
                    {attachmentSections[section].map((attachment, index) => (
                        <div className='ProfileView_x' key={index}>
                            <div className='ProfileView_subContainer-2'>
                                <div className='ProfileView_details'>
                                    <div className='ProfileView_subDetails'>
                                        <div className={isEditing === attachment.fieldKey ? 'ProfileView_subTitleEditing' : 'ProfileView_subTitle'}>
                                            <EditableLabel
                                                data={attachment}
                                                isEditing={isEditing}
                                                handleFieldLabelChange={handleFieldLabelChange}
                                                handleSaveType={handleSaveType}
                                                handleEditClick={handleEditClick}
                                                formPrefillData={true}
                                                title='Upload attachment before updating label'
                                            />
                                        </div>
                                        {attachment.fieldValue ? (
                                            <a target="_blank" rel="noreferrer" href={attachment.fieldMeta?.vurl_link}>
                                                {attachment.fieldValue}
                                            </a>
                                        ) : (
                                            <span onClick={() => openUploadModal(section, attachment.fieldLabel, attachment.fieldKey)} style={{ ...buttonStyle2 }}>
                                                <UploadOutlined style={{ ...buttonStyle2 }} />
                                                Upload
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {attachment.fieldValue && (
                                <div className='ProfileView_subContainer-2-1'>
                                    <div className='ProfileView_details-2'>
                                        <div className='ProfileView_subDetails-2'>
                                            <p onClick={() => handleActionClick(attachment.fieldMeta?.id, attachment.fieldType, attachment.fieldMeta)} style={{ ...buttonStyle2, minWidth: "200px", textAlign: "center" }}>{getRequestType(attachment.fieldType, attachment.fieldMeta)}</p>
                                            <div className='ProfileView_info' style={{ display: 'flex', alignItems: 'center' }}>
                                                <p>{attachment.fieldMeta?.expiry_date ? formatToCustomTime(attachment.fieldMeta?.expiry_date) : 'No Expiry'}</p>
                                                <span onClick={() => openDeleteModal(attachment.fieldMeta?.id, attachment.fieldKey)} style={{ ...buttonStyle2, cursor: "pointer" }}>Delete</span>
                                                <a target="_blank" rel="noreferrer" href={attachment.fieldMeta?.durl_link} style={{ ...buttonStyle2 }}>Download</a>
                                                <Button shape="circle" size="small" style={{ background: "#F5F5F5", border: "1px solid #ccc", color: "#656464" }} icon={<RightOutlined style={{ fontSize: "12px" }} />} onClick={() => showDrawer(attachment)} />
                                            </div>
                                            <Drawer
                                                title={<div>
                                                    <span style={{ display: "block", fontSize: "18px", textAlign: "left" }}>Attachment Details</span>
                                                </div>}
                                                placement="right"
                                                closable={true}
                                                size='large'
                                                key={attachment.fieldKey}
                                                onClose={onClose}
                                                open={open}
                                                getContainer={false}
                                            >
                                                <h4 style={{ margin: "0px 0px 5px 0px", textAlign: "left" }}>File Informations</h4>
                                                <Table
                                                    columns={columns}
                                                    showHeader={false}
                                                    bordered={true}
                                                    dataSource={dataSource}
                                                    pagination={false}
                                                    style={{ marginBottom: '20px' }}
                                                />
                                                <div>
                                                    <embed src={linkTorender.fieldMeta?.vurl_link} width="100%" height="600px" />
                                                </div>
                                            </Drawer>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <Button onClick={handleNewAttachment} style={buttonStyle}><PlusOutlined /> New Attachment</Button>
            {billingModalId && (
                <Modal
                    title='Add to Expense'
                    visible={billingModalVisible}
                    onCancel={closeBillingModal}
                    footer={null}
                    className="Category_modal"
                >
                    <div className='CategoryModal_content'>
                        <div className='CategoryModal_content-1'>
                            <div className='CategoryModal_content-1-1'>
                                <p>Amount</p>
                                <Input
                                    placeholder='Amount'
                                    value={billingData.amount}
                                    onChange={e => handleBillingInputChange('amount', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='CategoryModal_content-1'>
                           
                            <div className='CategoryModal_content-1-1'>
                                <p>Payment Date</p>
                                <Input
                                    placeholder='Payment Date'
                                    value={billingData.paymentDate}
                                    onChange={e => handleBillingInputChange('paymentDate', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='CategoryModal_content-1'>
                            <div className='CategoryModal_content-1-1'>
                                <p>Payment Reference</p>
                                <Input
                                    placeholder='Payment Reference'
                                    value={billingData.paymentReference}
                                    onChange={e => handleBillingInputChange('paymentReference', e.target.value)}
                                />
                            </div>
                            <div className='CategoryModal_content-1-1'>
                                <p>Payment Status</p>
                                <Input
                                    placeholder='Payment Status'
                                    value={billingData.paymentStatus}
                                    onChange={e => handleBillingInputChange('paymentStatus', e.target.value)}
                                />
                            </div>
                        </div>
                        <Button onClick={handleSaveClick} type='primary' style={{ width: '100%' }}>Add</Button>
                    </div>
                </Modal>
            )}
        </section>
    );
}