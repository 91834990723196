import { Button, DatePicker, Input, Modal, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import RadioSelect from '../../common/RadioSelect'
import { UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { showLoader } from '../../../utils';

export default function ModalUploadComponent({isVisible, onClose, handleModalSubmit, modalData: options, setUploadData, isLoading }) {

  const [value, setValue] = useState(null);
  const [label, setlabel] = useState(null);
  const [expiry, setExpiry] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setValue(setUploadData?.value)
    setlabel(setUploadData?.label)
    setFile(null)
  }, [setUploadData])

  const handleUpload = async (file) => {
    setFile(file)
  }

  const handleSelect = async (option) => {
    setValue(option.value)
    setlabel(option.label)
  }
  const handleRemove = () => {
    setFile(null);
  }

  const isButtonDisabled = !value || !label;
  
  return (
    <Modal
        title='Upload New Attachment'
        open={isVisible}
        onCancel={onClose}
        footer={null} 
        className="Upload_modal"
    >
        <div className='Upload_content'>
        <RadioSelect options={options} handleSelect={handleSelect} setOption={setUploadData} />
        <div className='Upload_subContent'>
            <div className='Upload_subContent-2'>
                <label>Attachment Label</label>
                <Input  placeholder='Ex: Attachment 1' value={label} onChange={(e) => setlabel(e.target.value)}/>
            </div>
            <DatePicker placeholder='Expiry Date' format="YYYY-MM-DD" value={expiry? dayjs(expiry): null}
             onChange={(_date, dateString) => setExpiry(dateString)} />
        </div>
        <Upload
          showUploadList={true}
          fileList={file ? [file] : []}
          accept=".pdf, .png, .jpeg, .jpg, .csv, .doc, .docx"
          beforeUpload={handleUpload}
          customRequest={() => handleModalSubmit(file, label, value, expiry)} 
          onRemove={handleRemove}
          multiple={false}
        >
          <Button type="primary" icon={<UploadOutlined />} disabled={isButtonDisabled} className={isButtonDisabled ? 'disabled' : ''}>Add File to Upload</Button>
        </Upload>
        </div>
        {isLoading && showLoader("Uploading")} 
    </Modal>
  )
}
