import { CheckCircleFilled } from "@ant-design/icons";
import { Progress, Space } from "antd";
import React , {useEffect, useState}from "react";
import { Link } from "react-router-dom";
import "./ProgressBarComponent.css";
import { useSelector } from "react-redux";

export default function ProgressBarComponent({ vendorData, vendorListCount, progressData, width= "96%", margin }) {
  const [modifiedProgressData, setModifiedProgressData] = useState([]);
  useEffect(() => {
    if(!progressData) return;
    let modifiedProgressData  = progressData.sectionProgress && Object.keys(progressData.sectionProgress).map((key, index) => {
      return {
        id: index + 1,
        title: key,
        progress: progressData.sectionProgress[key],
        completedText: " completed",
        link: "/home/account-info",
        progressText: `${progressData.sectionProgress[key]}%`,
      }
    });
    modifiedProgressData  = modifiedProgressData? modifiedProgressData : [];
    setModifiedProgressData(modifiedProgressData);
  }, [progressData]);

  const user = useSelector((state) => state.user);

  // const modifiedProgressData = [
  //   {
  //     id: 1,
  //     type: 'enterprise',
  //     title: "Update Account Information",
  //     progress: user && user?.org ? 100 : 0,
  //     completedText: " completed",
  //     link: "/home/account-info",
  //     progressText: user && user?.org ? "100%" : "0%",
  //   },
  //   {
  //     id: 2,
  //     type: 'enterprise',
  //     title: ((vendorData > 0 && vendorData <= vendorListCount[0]?.minVendorsListCount) && 'Add More Vendors') || (vendorData === 0 && 'Add Vendors'),
  //     progress: calculateProgress(vendorData, vendorListCount[0]?.minVendorsListCount),
  //     completedText: " completed",
  //     link: "/home/vendor-add",
  //     progressText: `${calculateProgress(vendorData, vendorListCount[0]?.minVendorsListCount)}%`
  //   },
  //   {
  //     id: 1,
  //     type: 'vendor',
  //     title: "Add business information",
  //     progress: calculateVendorProgress(user?.org, 1),
  //     completedText: " completed",
  //     link: "/home/enterprise-data",
  //     progressText: `${calculateVendorProgress(user?.org, 1)}%`,
  //   },
  //   {
  //     id: 2,
  //     type: 'vendor',
  //     title: "Add all attchments",
  //     progress: calculateVendorProgress(user?.org, 2),
  //     completedText: " completed",
  //     link: "/home/vendor-tasks",
  //     progressText: `${calculateVendorProgress(user?.org, 2)}%`,
  //   },
  // ];

  const calculateAverageProgress = (progressData) => {
    const totalProgress = progressData.reduce(
      (total, step) => total + step.progress,
      0
    );
    const averageProgress = totalProgress / progressData.length;
    return Math.round(averageProgress);
};
  const filteredProgressData = modifiedProgressData;
  const averageProgress = calculateAverageProgress(filteredProgressData);

  return (
    <div className="ProgressBarComponent_container" style={{margin: margin?margin : "20px 0px 10px 0px",    padding: "0px 1% 20px 3%", width: width, overflow: "hidden", background: "#fff", gap: '0px'}}>
      <h5 style={{color: "#000"}}>Track your progress</h5>
      <div className="ProgressBarComponent_subContainer" style={{padding: "0px"}}>
        <div className="ProgressBarComponent_circle">
          <Space wrap>
            <Progress
              type="circle"
              percent={averageProgress}
              width={80}
              strokeWidth={6}
              strokeColor={user?.orgType === 'vendor' ? '#008065' : '#4800FF'}
            />
          </Space>
        </div>
        <div className="ProgressBarComponent_progressContainer" style={{overflow: "hidden", overflowX: "scroll", width: "87%"}}>
            <p style={{color: "rgba(0, 0, 0, 0.45)"}}>Finish the following mandatory steps:</p>
          <div className="ProgressBarComponent_content">
            {filteredProgressData.map((step, index) => (
              <div className="hr-combined">
                <div className="ProgressBarComponent_text" key={index} style={{minWidth: "0px"}}>
                  <div className={`ProgressBarComponent_subText ${user?.orgType === 'vendor' && 'colorG'}`}>
                    {step.progress === 100 ? (
                      <CheckCircleFilled />
                    ) : (
                      <span>{step.id}</span>
                    )}
                    
                    <div style={{textAlign: 'start'}}>
                    <p style={{paddingLeft: "0px", color: "#000"}}>{step.progressText}</p>
                    </div>
                    {index !== filteredProgressData.length - 1 && (
                  <div className="ProgressBarComponent_hr"  style={{width: "15px"}}><hr/></div>
                )}
                  </div>
                  <p style={{padding: '0px', textAlign: "left", color: "#000"}}>
                  {step.title}
                   
                  </p>
                </div>
                
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
