import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Pagination, Checkbox } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import StepByStepAdditionComponent from './StepByStepAdditionComponent';
import RequestNudgeComponent from '../../nudge/RequestNudgeComponent';
import BreadcrumbComponent from '../../common/BreadcrumbComponent';
import { ERROR_CTA_MAP, BASE_URL } from '../../../constants';
import RegistrationComponent from './RegistrationComponent';
import { fetchData, postData } from '../../../services/HomeService';
import './style.css';
import BulkUploadComponent from './BulkUploadComponent';
import AddManualComponent from './AddManualComponent';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { downloadCSVFile } from '../../../utils';
import StarredProfiles from './StarredProfiles';
import { Switch } from 'antd';

export default function VendorOnboard({ selectedParent, selectedChild }) {
    const [visible, setVisible] = useState(false);
    const [csvData, setCSVData] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const [isBulkUpload, setIsBulkUpload] = useState(false);
    const [isCSVUploaded, setIsCSVUploaded] = useState(false);
    const [showStepByStep, setShowStepByStep] = useState(false);
    const [vendorSelections, setVendorSelections] = useState({});
    const [selectedVendorId, setSelectedVendorId] = useState([]);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [sorting, setSorting] = useState({ field: 'name', order: 'desc' });
    const [pagination, setPagination] = useState({ page: 1, limit: 10, total: 0 });

    const [profiles, setProfiles] = useState([]);
    const user = useSelector((state) => state.user);

    const handlePageChange = (page, pageSize) => {
        setPagination({ ...pagination, page, limit: pageSize });
        setSelectAll(false);
        setVendorSelections({})
    };
    const [formNames, setFormNames] = useState([]);
    const [selectedFormName, setSelectedFormName] = useState('');
    const [isSwitchChecked, setIsSwitchChecked] = useState();
    useEffect(() => {
        const fetchFormNames = async () => {
            const response = await fetchData('/partners/register/vendor/getalltemplates');
            if(response.success){
                const data = response.data;
                const formNamesArray = Object.keys(data).map(key => ({
                    value: key,
                    label: data[key].label,
                    fields: data[key].fields,
                    type: data[key].type
                }));
                setFormNames(formNamesArray);
                const defaultForm = formNamesArray.find(form => form.type == 'default');
                if (defaultForm) {
                    setSelectedFormName(defaultForm.value);
                    setIsSwitchChecked(formNamesArray.indexOf(defaultForm) == 1);
                    setResponseData(defaultForm.fields);
                }
            }
        }
        fetchFormNames();
    }, []);

    const handleStepByStepClick = () => {
        setShowStepByStep(true);
    };

    const handleManualUpload = () => {
        setIsCSVUploaded(true);
    };

    const handleCSVUpload = (data) => {
        setIsCSVUploaded(true);
        setIsBulkUpload(true);
        setCSVData(data);
    };

    const handleBackClick = () => {
        setVisible(!(showStepByStep || isCSVUploaded));
        setIsCSVUploaded(false);
        setShowStepByStep(false);
    };

    const handleSortChange = (field, order = 'asc') => {
        setSorting({ field, order });
        setSelectAll(false);
        setVendorSelections({})
    };

    let additionalText = '';
    if (isCSVUploaded) {
        additionalText = 'Entries';
    } else if (showStepByStep) {
        additionalText = 'How to add a list';
    }
    useEffect(() => {
        const fetchProfiles = async () => {
            try {
                const response = await fetch(`${BASE_URL}/profiles?filter=starredBy-eq-${user.id}&page=${pagination.page}&limit=${pagination.limit}&sort=${sorting.field}-${sorting.order}`);
                const data = await response.json();
                setProfiles(data.data);
                setPagination({ ...pagination, total: data.total });
            } catch (error) {
                console.error('Error fetching profiles:', error);
            }
        };

        fetchProfiles();
    }, [pagination.page, pagination.limit, sorting.field, sorting.order]);
    const handleSwitchChange = (checked) => {
        setIsSwitchChecked(checked);
        const selectedForm = formNames[checked ? 1 : 0]; // Assuming the two forms are the first two in the array
        setSelectedFormName(selectedForm.value);
        setResponseData(selectedForm.fields);
    }

       const handleDownloadClick = async () => {
        try {
            const response = await fetchData('/partners/register/vendor?formname=' + selectedFormName);
            if(response.success){
                setResponseData(response.data);
                const fileName = 'vendor_onboarding_template.csv';
                const dataKeys = Object.keys(response.data);
                const formattedData = [Object.fromEntries(dataKeys.map(key => [key, '']))];
                const success = await downloadCSVFile(formattedData, fileName);
                return { success, response };
            }
        } catch (error) {
            console.log('Download Error:', error);
            return false;
        }
    }

    const handleSelectAllToggle = () => {
        let updatedVendorSelections = {};
        let selectedIds = [];

        if (selectAll) {
            updatedVendorSelections = {};
            selectedIds = [];
        } else {
            profiles.forEach(vendor => {
                updatedVendorSelections[vendor._id] = true;
                selectedIds.push(vendor._id);
            })
        }

        setVendorSelections(updatedVendorSelections);
        setSelectAll(!selectAll);
        setSelectedVendorId(selectedIds);
    };

    const openAllRemoveVendorModal = () => {
        setShowRemoveModal(true)
    }

    const closeAllRemoveVendorModal = () => {
        setShowRemoveModal(false)
    }

    const handleFileUpload = (file) => {
        if (!file) {
            return { success: false, errorMessage: ERROR_CTA_MAP['FILE_UPLOAD_ERROR'] };
        }
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop().toLowerCase();
        if (fileExtension !== 'csv') {
            return { success: false, errorMessage: ERROR_CTA_MAP['FILE_TYPE_ERROR'] };
        }

        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onload = (e) => {
                let csvData = e.target.result;
                resolve({ success: true, csvData });
            };
            reader.readAsText(file);
        });
    }
    const handleBulkRemove = async () => {
        const payload = { profileIds: selectedVendorId }
        const res = await postData('/profiles/updatestar/star', payload);

        const temp = profiles.filter(val => selectedVendorId.indexOf(val._id) === -1);
        setProfiles(temp);
        setSelectedVendorId([]);
        closeAllRemoveVendorModal();
    }

    return (
        <main className='Home_container'>
            <section className='Home_firstContainer'>
                <BreadcrumbComponent selectedParent={selectedParent} selectedChild={selectedChild} additionalText={additionalText} />
                <div className='Home_heading'>
                    {isCSVUploaded ? (
                        <>
                            <ArrowLeftOutlined className='VendorOnboarding_arrowBack' onClick={handleBackClick} />
                            <h1>Enter Details</h1>
                        </>
                    ) : showStepByStep ? (
                        <>
                            <ArrowLeftOutlined className='VendorOnboarding_arrowBack' onClick={handleBackClick} />
                            <h1>Steps to Add in Bulk</h1>
                        </>
                    ) : (
                        <h1>{selectedChild}</h1>
                    )}
                </div>
            </section>
            {!isCSVUploaded && (
            <div className='Toggler_main' style={{margin: "24px 24px 0px 24px"}}>
                <div className='Toggler_containerLeft'>
                    {formNames.length >= 2 && (
                        <div>
                            <span>{formNames[0].label}</span>
                            <Switch
                                checked={isSwitchChecked}
                                onChange={handleSwitchChange}
                                style={{backgroundColor: isSwitchChecked ? '#5121fd' : '#5121fd'}}
                            />
                            <span style={{padding: "5px"}}>{formNames[1].label}</span>
                        </div>
                    )}
                </div>
            </div>)}
            { selectedFormName && selectedFormName == 'invitation' ?
            <div className='Toggler_main_form' style={{margin: "24px 24px 0px 24px"}}>
            <RegistrationComponent prefillmap={csvData} template={responseData} registerType={'vendor'} isBulkUpload={isBulkUpload} formname={selectedFormName} minimal={true}/>
            </div> : <section className="VendorOnboard_adding">
                    {isCSVUploaded ? (
                        <RegistrationComponent prefillmap={csvData} template={responseData} registerType={'vendor'} isBulkUpload={isBulkUpload} formname={selectedFormName}/>
                    ) : showStepByStep ? (
                        <StepByStepAdditionComponent onBackClick={handleBackClick} onCSVUpload={handleCSVUpload} handleDownloadClick={handleDownloadClick} handleFileUpload={handleFileUpload} />
                    ) : (
                        <>
                            <BulkUploadComponent visible={visible} setVisible={setVisible} onCSVUpload={handleCSVUpload} onStepByStepClick={handleStepByStepClick} handleDownloadClick={handleDownloadClick} handleFileUpload={handleFileUpload} />
                            <AddManualComponent onManualUpload={handleManualUpload} />
                        </>
                    )}
                </section>
            }
            {profiles.length > 0 && !isCSVUploaded &&
                <section className='starred-profiles' style={{ padding: "24px" }} >
                    <h2 style={{ textAlign: "left" }}>Starred Profiles</h2>
                    <section className="VendorLists_adding" style={{ padding: 0 }}>
                        {pagination.total > 1 && (
                            <section className='ListComponent_sorter'>
                                <div className='ListComponent_radio'>
                                    <Checkbox
                                        onClick={handleSelectAllToggle}
                                        checked={selectAll}
                                    >
                                        Select all
                                    </Checkbox>
                                </div>
                                <div className='ListComponent_radioOption'>
                                    <p
                                        className={Object.keys(vendorSelections).length > 1 ? "" : "ListComponent_disabled"}
                                        onClick={() => Object.keys(vendorSelections).length > 1 && openAllRemoveVendorModal()}
                                    >
                                        Remove
                                    </p>
                                </div>
                            </section>
                        )}
                    </section>
                    <section className="ListComponent_sorter">
                        <div className='ListComponent_name'>
                            <p>Name</p>
                            <div className='ListComponent_sorterIcon'>
                                <CaretUpOutlined onClick={() => handleSortChange('name', 'asc')} />
                                <CaretDownOutlined onClick={() => handleSortChange('name', 'desc')} />
                            </div>
                        </div>
                        <div className='ListComponent_name'>
                            <p>Location</p>
                            <div className='ListComponent_sorterIcon'>
                                <CaretUpOutlined onClick={() => handleSortChange('primeLocation', 'asc')} />
                                <CaretDownOutlined onClick={() => handleSortChange('primeLocation', 'desc')} />
                            </div>
                        </div>
                        <p>Tag</p>
                        <p>Actions</p>
                    </section>
                    <div className='starred-profiles-container'>
                        <StarredProfiles
                            profiles={profiles}
                            user={user}
                            selectAll={selectAll}
                            setProfiles={setProfiles}
                            setSelectAll={setSelectAll}
                            vendorSelections={vendorSelections}
                            setVendorSelections={setVendorSelections}
                            setAllSelectedVendorId={setSelectedVendorId}
                        />
                    </div>
                    <div className="ListComponent_container" style={{ width: '100%' }}>
                        <Pagination
                            total={pagination.total}
                            current={pagination.page}
                            pageSize={pagination.limit}
                            showSizeChanger
                            showTotal={(total) => `Total ${total} items`}
                            onChange={handlePageChange}
                            onShowSizeChange={(current, size) => {
                                setPagination({ ...pagination, page: current, limit: size });
                            }}
                        />
                    </div>
                </section>
            }

            <RequestNudgeComponent
                type={'MultipleRemoveVendor'}
                action={'MultipleRemove'}
                data={selectedVendorId}
                isVisible={showRemoveModal}
                onClose={closeAllRemoveVendorModal}
                handleNudgeSubmit={handleBulkRemove}
            />
        </main>
    );
}
